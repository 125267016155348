.App {
  padding: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.akaiLogo {
  background-image: url(./images/Akai\ House\ 600\ Logo.png);
  background-size: contain;
  width: 300px;
  height: 100px;
  background-repeat: no-repeat;
}
.buttonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.buttonsCSS {
  margin: 5px;
}
